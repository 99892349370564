import { FingerprintService } from './FingerprintJS/FingerprintService';
import { deferCall } from '../../../helpers/deferCall';
import { devConsole } from '../../../utils/DevConsole';
import { globalErrorHandler, globalLogHandler } from '../../../utils/LogUtils';
import { MiscUtils } from '../../../utils/MiscUtils';
import { environment } from '../../config/environment';
import { EnvironmentName } from '../../constants/Environment';
import { SUBSCRIPTION_PLAN_NAME, SubscriptionPlans } from '../../constants/SubscriptionPlan';
import { LS_COOKIE_CONSTS } from '../../models/Enums';
import {
  SubscriptionAutoRenew,
  SubscriptionLifeCycle,
  SubscriptionStatus
} from '../../models/Subscription/SubscriptionData';
import { GemsAnalyticsShopLocations } from '../../store/ducks/leanplum/lpAnalytics';
import { CookieService } from '../CookieService';
import {
  gemsGetCurrentAmount,
  gemsIsFirstPurchase,
  gemsVirtualItemsSpent,
  getGemsVirtualItemsSpentFromLocalStorage
} from '../GemsService';
import { LocalStorageService } from '../LocalStorage';
import PaymentService from '../PaymentService';
import { UrlService } from '../UrlService';
import UserService from '../UserService';

const Leanplum = !MiscUtils.isServer && (window as any).Leanplum ? (window as any).Leanplum : null;

export const LP_START_TO = 1000;
export const getLeanplum = () => (!MiscUtils.isServer && (window as any).Leanplum ? (window as any).Leanplum : null);
// This value should be set to true only if you're developing on your server.
// dev3 is added for testing prod env
const isDevelopmentMode = environment.Name !== EnvironmentName.PRODUCTION
  && environment.Name !== EnvironmentName.STAGING
  && environment.Name !== EnvironmentName.CANARY;

enum leanplumConstants {
  API_URL = 'https://api.leanplum.com/api',
  EMAIL_CONSENT_CHANNEL_NAME = 'Email',
  EMAIL_CONSENT_ACTION_NAME = 'setUserAttributes',
  EMAIL_CONSENT_PROP_TRUE = 'unsubscribeChannelsToRemove',
  EMAIL_CONSENT_PROP_FALSE = 'unsubscribeChannelsToAdd',
}

export enum LEANPLUM_EVENTS {
  FUNNEL_CONTENT_START = 'funnelContentStart',
  FUNNEL_CONTENT_END = 'funnelContentEnd',
  LOGIN_BTN = 'loginButton',
  REGISTRATION = 'registration',
  SUBSCRIPTION_PURCHASE = 'subscriptionPurchase',
  CLAIM_ENTRY = 'claimEntry',
  CAROUSEL_BUTTON = 'carouselButton',
  PAGEVIEW = 'pageview',
  TOAST_BANNER_BUTTON = 'toastBannerButton',
}

type LeanplumUserAttributes = {
  // common (default batch)
  user_id?: string;
  user_name?: string;
  user_last_name?: string;
  user_email?: string;
  registered?: boolean;
  subscriber?: boolean;
  subscription_status?: SubscriptionStatus;
  subscription_autorenew?: SubscriptionAutoRenew;
  subscription_plan?: SUBSCRIPTION_PLAN_NAME;
  subscription_lifecycle?: SubscriptionLifeCycle | string;
  loggedIn?: boolean;
  customTimerWakeUp?: number;
  current_page?: string; // url of page where user is currently
  emailConsent?: boolean; // areal val is set via API call, just to update ToDo: remove when implemented on Eagle
  // special (passed single)
  last_game_played?: string | false;
  userInventory?: any; // object of user's current balance of {gems, etc}
  virtualItemSpent?: boolean; // user spent gems at least once (call once for TRUE val => only type true)
};

export class LeanplumAnalytics {
  static init() {
    const self = this;

    // Get your App ID and Keys from https://www.leanplum.com/dashboard?#/account/apps
    if (isDevelopmentMode) {
      Leanplum?.setAppIdForDevelopmentMode(environment.LEANPLUM_APP_ID, environment.LEANPLUM_API_KEY);
    } else {
      Leanplum?.setAppIdForProductionMode(environment.LEANPLUM_APP_ID, environment.LEANPLUM_API_KEY);
    }

    Leanplum?.enableRichInAppMessages(true);
    Leanplum?.addStartResponseHandler(function(success) {
      LeanplumStartCallback.bind(self)(success);
    });
  }

  static async preparePushNotificationsWorker() {
    // Adding push notifications functionality
    if (Leanplum) {
      const isWebPushSupported = await Leanplum.isWebPushSupported();

      if (!isWebPushSupported) {
        return globalLogHandler({
          msg: 'LP push-subscriptions not supported',
          filename: 'LeanplumAnalytics.ts',
          value: null
        });
      }

      // make push notifications be shown every time => handled on LP admin side now
      deferCall(async () => {
        let isSubscribedToWebPush = false;
        let isWebPushSupported = Leanplum.isWebPushSupported();

        if (isWebPushSupported) {
          try {
            const subscriptionStatus = await Leanplum.isWebPushSubscribed();

            isSubscribedToWebPush = subscriptionStatus;
          } catch (error) {
            globalErrorHandler({ error, filename: 'LeanplumAnalytics.ts', info: 'Leanplum.isWebPushSubscribed()' });
          }
        }

        globalLogHandler({
          msg: 'LP push-subscription status (INIT)',
          filename: 'LeanplumAnalytics.ts',
          value: `isSubscribedToWebPush: ${isSubscribedToWebPush}`
        });

        if (isWebPushSupported && !isSubscribedToWebPush) {
          Leanplum.registerForWebPush(`/leanplum_sw.min.js?restarter=${new Date().getTime()}`).then(subscriptionStatus => {
            globalLogHandler({
              msg: 'LP push-subscription status (SW)',
              filename: 'LeanplumAnalytics.ts',
              value: `${subscriptionStatus}`
            });
          }).catch(error => {
            globalErrorHandler({ error, filename: 'LeanplumAnalytics.ts', info: 'Leanplum.registerForWebPush()' });
          });
        }
      });
    }
  }

  static startTO: any = null;
  static startFirstRunFlag: boolean = true;

  static start(userUid, lpDelayWakeUp, callback = null) {
    const self = this;

    if (this.startTO) {
      // debouncer is for logged-in user loads page and changes uid state after login
      clearTimeout(this.startTO);
    }

    if (!userUid && self.startFirstRunFlag) {
      // handling initial start of logged-in user
      this.startTO = setTimeout(async () => await LeanplumStart(), LP_START_TO);
    } else {
      // handling logout during session, login and re-login during session
      deferCall(
        async () =>
          self.setUserAttributesCustom({
            current_page: getCurrentPageUserAttribute(),
            loggedIn: UserService.isUserLoggedIn()
          })
      );
      deferCall(async () => await LeanplumStart());
    }

    async function LeanplumStart() {
      if (!self.startFirstRunFlag) {
        deferCall(async () => await Leanplum?.stop?.()); // STOP() old session
      }

      if (userUid) {
        deferCall(async () => {
          if (userUid !== Leanplum?.getUserId()) {
            await Leanplum?.setUserId?.(userUid);
          }
        }); // If user logged in during both inital run or later login
        deferCall(async () => await self.setUserAttributes(true)); // SET ATTRIBUTES to be used on startup of new session
      }

      deferCall(
        async () =>
          await Leanplum?.start?.({ ...(await self.getUserAttributes()) }, callback ? callback : () => {
          })
      ); // START() new session
      deferCall(async () => await LeanplumAnalytics.preparePushNotificationsWorker());

      if (
        LocalStorageService.getItem(LS_LP_PROPS.WAKEUP_SENT) &&
        userUid !== LocalStorageService.getItem(LS_LP_PROPS.WAKEUP_SENT) &&
        !Boolean(FingerprintService.detected?.fpIncognito)
      ) {
        CustomTimerWakeUpUser();
      }
    }

    function CustomTimerWakeUpUser() {
      setTimeout(() => {
        deferCall(() => self.setUserAttributesCustom({ customTimerWakeUp: new Date().getTime() }));
      }, lpDelayWakeUp | 5500);
      LocalStorageService.setItem(LS_LP_PROPS.WAKEUP_SENT, userUid || true);
    }
  }

  static async setUserAttributes(forceUpdate = false) {
    const self = this;
    const payload = { ...(await self.getUserAttributes()) };

    if (shouldDoUpdate(payload, LS_LP_PROPS.USER_ATTRIBUTES) || forceUpdate) {
      Leanplum?.setUserAttributes(payload);
      deferCall(async () => await Leanplum?.forceContentUpdate());
    }
  }

  static setUserAttributesCustom(payload: LeanplumUserAttributes, forceUpdate = false) {
    if (shouldDoUpdate({ ...payload }, LS_LP_PROPS.USER_ATTRIBUTES_CUSTOM) || forceUpdate) {
      // We can use it with special attribute to update single / special attribute / attributes only
      // Because "User properties not supplied in this method will not be affected." https://docs.leanplum.com/reference/post_api-action-setuserattributes
      Leanplum?.setUserAttributes({ ...payload });
      deferCall(async () => await Leanplum?.forceContentUpdate());
    }
  }

  static trackEvent(event: string, payload: any = {}, forceUpdate = true) {
    const self = this;

    devConsole('LeanplumAnalytics.trackEvent', event, payload, {
      forceUpdate,
      startFirstRunFlag: self.startFirstRunFlag
    });

    if (!self.startFirstRunFlag) {
      const update = { event, payload };

      if (shouldDoUpdate(update, LS_LP_PROPS.LAST_TRACKED) || forceUpdate) {
        Leanplum?.track(event, { ...self.baseParams(), ...payload });
      }
    } else {
      // to wait for doing track when session has been started
      setTimeout(function() {
        self.trackEvent(event, payload, forceUpdate);
      }, LP_START_TO + 500);
    }
  }

  static setUserID = (id?: string | null) => {
    if (Leanplum && id && id !== Leanplum.getUserId()) {
      Leanplum.setUserId(id);
    } else {
      globalErrorHandler({ error: new Error('Leanplum.setUserID call issue'), filename: 'LeanplumAnalytics.ts', info: `ID to set: ${id}, Current LP ID: ${Leanplum?.getUserId()}` });
    }

  };

  static baseParams() {
    const domain = UrlService.domain;
    const offsitepromo =
      UrlService.getQSParam(window.location.search, 'arkpromo') || LocalStorageService.getItem('arkpromo', true);
    const loggedIn = UserService.isUserLoggedIn();
    const page = window.location.pathname;
    const affiliateCookie = CookieService.getArkCookie(LS_COOKIE_CONSTS.ARK_AFFILIATE);
    const affiliateName = affiliateCookie ? affiliateCookie : undefined;
    const affiliate = Boolean(affiliateName);

    return {
      domain,
      offsitepromo,
      loggedIn,
      page,
      affiliate
    };
  }

  static async getUserAttributes(): Promise<LeanplumUserAttributes> {
    const user = UserService.getUserFromStore();
    const loggedIn = UserService.isUserLoggedIn();
    const current_page: string = getCurrentPageUserAttribute();
    let loggedInAttributes = {};

    // setUserAttributes is called only when there is uid, but to avoid effect from accidental call of method let's add condition
    if (user && loggedIn) {
      const { uid, name, lastName, email, emailRetrievalConsent } = user;
      const emailConsent = Boolean(emailRetrievalConsent);
      // gems
      const virtualItemSpent = getGemsVirtualItemsSpentFromLocalStorage();
      const userInventory = await gemsUpdateUserInventory();
      // Using honest request to fix unhandled issue when store is not updated in AppBody when AppBodyWrapper runs analytics
      let activeUserSubscriptions = null;
      let expiredUserSubscriptions = null;

      try {
        activeUserSubscriptions = await PaymentService.getSubscriptions();
        expiredUserSubscriptions = await PaymentService.getExpiredSubscriptions();
      } catch (e) {
      }

      const currentUserSubscription = activeUserSubscriptions?.length ? activeUserSubscriptions[0] : null;

      loggedInAttributes = {
        user_id: uid,
        user_name: name,
        user_last_name: lastName,
        user_email: email,
        registered: Boolean(user),
        subscriber: UserService.isUserSubscriber(),
        subscription_status: UserService.getSubscriptionStatus(
          activeUserSubscriptions,
          expiredUserSubscriptions
        ),
        subscription_autorenew: UserService.checkSubscriptionAutoRenew(currentUserSubscription),
        subscription_plan: UserService.getSubscriptionType(currentUserSubscription),
        subscription_lifecycle: UserService.getSubscriptionLifecycleParam(currentUserSubscription),
        emailConsent,
        virtualItemSpent,
        userInventory
      };
    }

    return {
      loggedIn,
      current_page,
      ...loggedInAttributes
    };
  }

  static async setEmailConsent(value: boolean) {
    const user = UserService.getUserFromStore();
    const loggedIn = UserService.isUserLoggedIn();

    // it's direct api call, so let's double check we can do it
    if (user && loggedIn) {
      const subscribeAttrsArr = [
        leanplumConstants.EMAIL_CONSENT_PROP_TRUE,
        leanplumConstants.EMAIL_CONSENT_PROP_FALSE
      ];
      const usedProp = value ? subscribeAttrsArr[0] : subscribeAttrsArr[1];
      const usedValue = leanplumConstants.EMAIL_CONSENT_CHANNEL_NAME;
      const appId = environment.LEANPLUM_APP_ID;
      const clientKey = environment.LEANPLUM_API_KEY;
      const action = leanplumConstants.EMAIL_CONSENT_ACTION_NAME;
      const userId = user.uid;
      const data = {
        appId,
        clientKey,
        action,
        userId,
        [usedProp]: usedValue
      };
      const urlBase = new URL(leanplumConstants.API_URL);
      const urlSearch = new URLSearchParams(data).toString();
      const url = `${urlBase}?${urlSearch}`;

      try {
        // @ts-ignore
        await fetch(url);
      } catch (e) {
        globalErrorHandler({ error: e, filename: 'LeanplumAnalytics.ts', info: 'setEmailConsent()' });
      }
    }
  }


  static startCampaign(payload) {
    fetch(`${leanplumConstants.API_URL}?action=startCampaign`, {
      method: 'POST',
      body: payload
    });
  }
}

// LEANPLUM GAME TRACKING

enum LeanplumGameEventNames {
  FUNNEL_REWARD_START = 'funnelRewardStart',
}

export class LeanplumGameTrack {
  static funnelRewardStart(game: string) {
    // when a user watches a rewarded ads video while gameplay
    return LeanplumAnalytics.trackEvent(LeanplumGameEventNames.FUNNEL_REWARD_START, { game });
  }
}

// LEANPLUM GEMS TRACKING

enum LeanplumGemEventNames {
  GEM_PURCHASE_CLICK = 'virtualItemPurchaseClick',
  GEM_PURCHASE_NEXT_STEP = 'virtualItemPurchaseNextStep',
  GEM_PURCHASE_SUCCESS = 'virtualItemPurchaseSuccess',
  GEM_SPEND = 'virtualItemSpend',
}

type LeanplumGemsGetEventDimensions = {
  purchasableItemId: string;
  purchasableItemAmount: number;
  shopLocation: GemsAnalyticsShopLocations | string;
  isFirst?: boolean;
};

type LeanplumGemsSpendEventDimensions = {
  virtualItemId?: unknown;
  internalPrice?: number;
  shopLocation: GemsAnalyticsShopLocations;
  isFirst?: boolean;
};

export class LeanplumGemsTrack {
  static async virtualItemPurchaseClick(payload: LeanplumGemsGetEventDimensions) {
    const isFirst = await gemsIsFirstPurchase();

    // when a user clicks on Gem item (gem pack card) in Gems shop (tracks intent to purchase) -- only on gem tab, not when change?)
    return LeanplumAnalytics.trackEvent(LeanplumGemEventNames.GEM_PURCHASE_CLICK, { ...payload, isFirst });
  }

  static async virtualItemPurchaseNextStep(payload: LeanplumGemsGetEventDimensions) {
    const isFirst = await gemsIsFirstPurchase();

    // when a user clicks "Next step" after providing payment info -- on purchase page
    return LeanplumAnalytics.trackEvent(LeanplumGemEventNames.GEM_PURCHASE_NEXT_STEP, { ...payload, isFirst });
  }

  static async virtualItemPurchaseSuccess(payload: LeanplumGemsGetEventDimensions) {
    const isFirst = await gemsIsFirstPurchase();

    // when a user clicks "Confirm" and purchases gems successfully -- on purchase page
    return LeanplumAnalytics.trackEvent(LeanplumGemEventNames.GEM_PURCHASE_SUCCESS, { ...payload, isFirst });
  }

  static async virtualItemSpend(payload: LeanplumGemsSpendEventDimensions) {
    // additional gems-related UserAttributes update
    LeanplumAnalytics.setUserAttributesCustom({ virtualItemSpent: true });
    LeanplumAnalytics.setUserAttributesCustom({ userInventory: await gemsUpdateUserInventory() });
    const spendGemsTransactions = await gemsVirtualItemsSpent();
    const isFirst = spendGemsTransactions === null || spendGemsTransactions === 1;
    const internalPrice = payload?.internalPrice || `isn't passed from game now`;

    // when a user spends gems for passing ads / game boost / game power up -- on game page, ToDo: track on high fives, etc new features too
    return LeanplumAnalytics.trackEvent(LeanplumGemEventNames.GEM_SPEND, { ...payload, internalPrice, isFirst });
  }
}

async function gemsUpdateUserInventory() {
  return { [environment.SKU_GEMS_NAME]: await gemsGetCurrentAmount() };
}

enum LS_LP_PROPS {
  USER_ATTRIBUTES = 'leanplumUserAttributes',
  USER_ATTRIBUTES_CUSTOM = 'leanplumUserAttributesCustom',
  LAST_TRACKED = 'leanplumLastTrackedEvent',
  WAKEUP_SENT = 'leanplumWakeupSent',
}

export function shouldDoUpdate(payload, lsPropNameClear) {
  // Using session storage not to affect other tabs and listener

  if (window === undefined) {
    return true;
  }

  const lsPropName = `${environment.STORAGE_PREFIX}${lsPropNameClear}`;
  let userAttributesOld;

  try {
    userAttributesOld = JSON.parse(window.sessionStorage.getItem(lsPropName));
  } catch (e) {
    userAttributesOld = {};
  }

  const userAttributesNew = {
    ...payload
  };
  const userAttributesOldJSON = JSON.stringify(userAttributesOld);
  const userAttributesNewJSON = JSON.stringify(userAttributesNew);
  const shouldUpdate = !userAttributesOld || userAttributesOldJSON !== userAttributesNewJSON;

  if (shouldUpdate) {
    window.sessionStorage.setItem(lsPropName, userAttributesNewJSON);
  }

  return shouldUpdate;
}

// RUNS FIRST (IN HANDLER), THEN PASSED CALLBACK AS SECOND
async function LeanplumStartCallback(success) {
  if (success) {
    await deferCall(async () => {
      // THIS FOR CAMPAIGNS TO TRIGGER NEW USER
      deferCall(
        async () =>
          await this.setUserAttributesCustom({
            current_page: getCurrentPageUserAttribute(),
            loggedIn: UserService.isUserLoggedIn()
          })
      );

      // FINALIZE INITIAL START()
      if (this.startFirstRunFlag) {
        deferCall(() => {
          this.startFirstRunFlag = false;
        }, LP_START_TO);
      }

      deferCall(async () => await Leanplum?.forceContentUpdate?.());
    });
  }
}

export const getSubscriptionTypeBySubscriptionPlanCode = (planCode) =>
  (planCode.match(/annual/gi) ? SubscriptionPlans.ANNUAL : SubscriptionPlans.MONTHLY).toLowerCase();

export function getCurrentPageUserAttribute() {
  return !MiscUtils.isServer && window?.location?.href ? window.location.href : 'null';
}
